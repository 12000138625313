<template>
  <div>
    <div class="form-item">
      <label class="form-label">Тип партнера*</label>
      <div class="form-select-wrapper">
        <template>
          <el-select class="form-select" :class="{ error: selectedPartnerTypes.length === 0 }"
            v-model="selectedPartnerTypes" :disabled="(!isAuthUserAsAdmin && !isAuthUserRegional) || disabled"
            placeholder="Выберите или введите название" multiple collapse-tags @change="formTouched()">
            <el-option class="select-checkbox" v-for="item in partnerTypes" :key="item.id" :label="item.name"
              :value="item.id"></el-option>
          </el-select>
        </template>
      </div>
      <p v-show="selectedPartnerTypes.length === 0" class="message-error">
        Выберите хотя бы один тип партнера
      </p>
    </div>
    <div class="form-item" v-if="selectedPartnerTypes.length">
      <div class="region-title">
        <p class="form-label">Название типа партнера</p>
      </div>
      <ul class="region-list">
        <li class="region-list__item" v-for="item in selectedPartnerTypesNames" v-bind:key="item.id">
          <p>{{ item.name }}</p>
          <div v-show="!disabled" class="cursor-pointer" @click="remove(item.id)">
            <img class="region-list__icon" src="@/assets/images/svg/close.svg" alt="удалить тип" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartnerType',
  props: {
    isAuthUserAsAdmin: {
      type: Boolean,
      default: false
    },
    isAuthUserRegional: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    directions: {
      type: Array,
      default: () => []
    },
    types: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedPartnerTypes: []
    }
  },
  watch: {
    types() {
      this.selectedPartnerTypes = this.types;
    },
    selectedPartnerTypes() {
      this.$emit('input', this.selectedPartnerTypes);
    },
    partnerTypes() {

    }
  },
  methods: {
    remove(id) {
      this.formTouched();
      this.selectedPartnerTypes = this.selectedPartnerTypes.filter((value) => value !== id);
    },
    formTouched() {
      this.$emit('change', true);
    },
    filterSelectedPartnerTypes(items) {
      this.selectedPartnerTypes = this.selectedPartnerTypes.filter((value) => items.some((item) => item.id === value));
    }
  },
  computed: {
    partnerTypes() {
      const allPartnerTypes = this.$store.getters['partner/getPartnerTypes'];
      const selectedDirections = this.selectedPartnerTypes.map((value) => allPartnerTypes.find((item) => item.id === value).direction);

      const items = allPartnerTypes.filter((type) => {
        const isAllowedDirection = this.directions.some(dir => dir.id === type.direction);
        const isDirectionSelected = selectedDirections.includes(type.direction);

        if (isAllowedDirection && isDirectionSelected) {
          return this.selectedPartnerTypes.some(selectType => type.id === selectType);
        }

        return isAllowedDirection;
      });

      this.filterSelectedPartnerTypes(items);

      return items;
    },
    selectedPartnerTypesNames() {
      if (!this.partnerTypes.length) return [];

      return this.selectedPartnerTypes
        .map((id) => this.partnerTypes.find((item) => item.id === id))
        .sort((a, b) => (a.name > b.name ? 1 : -1));
    }
  }
}
</script>

<style scoped></style>
